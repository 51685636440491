<template>
  <div class="expanBox">
    <mobileHeader />
    <div class="expanbanner">
      <img src="../../assets/m_expanbg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.expansion.bannerTitle") }}
      </div>
    </div>
    <div class="expanAdvantage">
      <div class="titles">{{ $t("lang.expansion.titlesOne") }}</div>
      <div class="partbox">
        <div class="part">
          <img src="../../assets/new1.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemOne.title") }}</div>
          <span>{{ $t("lang.expansion.itemOne.desc") }}</span>
        </div>
        <div class="part">
          <img src="../../assets/new2.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemTwo.title") }}</div>
          <span>{{ $t("lang.expansion.itemTwo.desc") }}</span>
        </div>
      </div>
      <div class="partbox">
        <div class="part">
          <img src="../../assets/new3.png" />
          <div class="partTitle">
            {{ $t("lang.expansion.itemThree.title") }}
          </div>
          <span>{{ $t("lang.expansion.itemThree.desc") }}</span>
        </div>
        <div class="part">
          <img src="../../assets/new4.png" />
          <div class="partTitle">{{ $t("lang.expansion.itemFour.title") }}</div>
          <span>{{ $t("lang.expansion.itemFour.desc") }}</span>
        </div>
      </div>
    </div>
    <div class="expanFooter">
      <div class="expancontainer">
        <div class="titleTop">{{ $t("lang.expansion.titleTop") }}</div>
        <div class="expandetail">
          <div class="detailPart">
            <div class="imgss">
              <img src="../../assets/newbg.jpg" />
            </div>
            <div>
              <div class="detailname">
                {{ $t("lang.expansion.cloudItemO.title") }}
              </div>
              <div class="detaillist">
                <span
                  :style="{
                    lineHeight: $i18n.locale === 'en-US' ? '30px' : '40px',
                  }"
                  >{{ $t("lang.expansion.cloudItemO.descOne") }}</span
                >
                <span
                  :style="{
                    lineHeight: $i18n.locale === 'en-US' ? '30px' : '40px',
                  }"
                  >{{ $t("lang.expansion.cloudItemO.descTwo") }}</span
                >
              </div>
            </div>
          </div>
          <div class="detailPart">
            <div class="imgss">
              <img src="../../assets/newbg2.jpg" />
            </div>
            <div>
              <div class="detailname">
                {{ $t("lang.expansion.cloudItemT.title") }}
              </div>
              <div class="detaillist">
                <span
                  :style="{
                    lineHeight: $i18n.locale === 'en-US' ? '30px' : '40px',
                  }"
                  >{{ $t("lang.expansion.cloudItemT.descOne") }}</span
                >
                <span
                  :style="{
                    lineHeight: $i18n.locale === 'en-US' ? '30px' : '40px',
                  }"
                  >{{ $t("lang.expansion.cloudItemT.descTwo") }}</span
                >
                <span
                  :style="{
                    lineHeight: $i18n.locale === 'en-US' ? '30px' : '40px',
                  }"
                  >{{ $t("lang.expansion.cloudItemT.descThree") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "expansion",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.expanBox {
  .expanbanner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .expanAdvantage {
    padding: 0 25px;
    margin-top: 50px;
    .titles {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .partbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .part {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .partTitle {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0 23px;
        width: 250px;
        text-align: center;
      }
      span {
        width: 250px;
        font-size: 16px;
        color: #6d7276;
        text-align: center;
      }
    }
  }
  .expanFooter {
    width: 100%;
    background: url("../../assets/m_exbg.jpg");
    background-size: 100% 100%;
    padding: 0 25px 50px;
    box-sizing: border-box;
    .expancontainer {
      margin: 0 auto;
      color: #ffffff;
      .titleTop {
        font-size: 15px;
        padding-top: 30px;
      }
      .expandetail {
        display: flex;
        flex-direction: column;
        align-items: center;
        .detailPart {
          margin-top: 40px;
          display: flex;
          flex-direction: row;
          width: 290px;
          .imgss {
            margin-right: 15px;
            margin-top: -8px;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .detailname {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: bold;
          }
          .detaillist {
            margin-left: 15px;
          }
          span {
            display: block;
            font-size: 14px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
